<script>
    export let content;

    function handleCopyClick(event) {
        navigator.clipboard.writeText(content);
    }
</script>

<section class="TextOutput">
    <textarea class="output" value={content} rows="20" />
    <div><button on:click={handleCopyClick}>Copy to clipboard</button></div>
</section>

<style>
    .output {
        width: 100%;
        resize: none;
    }
</style>
<script>
    import { createEventDispatcher } from "svelte";

    export let person;

    const dispatch = createEventDispatcher();
    const THROTTLE_PERIOD = 500;

    let name = person.name || "";
    let answer = person.answer || "";
    let timeoutId = null;

    function handleNameChanged(e) {
        name = e.target.value || "";
        // Throttle the event dispatches for performance
        if (timeoutId == null) {
            timeoutId = window.setTimeout(
                dispatchPersonChanged,
                THROTTLE_PERIOD
            );
        }
    }

    function handleAnswerChanged(e) {
        answer = e.target.value || "";
        // Throttle the event dispatches for performance
        if (timeoutId == null) {
            timeoutId = window.setTimeout(
                dispatchPersonChanged,
                THROTTLE_PERIOD
            );
        }
    }

    function dispatchPersonChanged() {
        timeoutId = null;
        const newAnswer = answer;
        const sortableAnswer = newAnswer.trim().toLowerCase();

        dispatch("personChanged", {
            person: {
                id: person.id,
                name: name,
                answer: newAnswer,
                sortableAnswer,
            },
        });
    }
</script>

<div class="NameInput">
    {#if person.isNameEditable}
        <input
            class="name-input"
            type="text"
            value={name}
            placeholder="Cthulhu"
            on:input={handleNameChanged} />
    {:else}
        <label for={`person-${person.id}`} class="name-label">{name}</label>
    {/if}
    <input
        type="text"
        id={`person-${person.id}`}
        class="answer-input"
        value={answer}
        on:input={handleAnswerChanged}
    />
</div>

<style>
    .NameInput {
        display: flex;
        flex-direction: column;

        align-items: baseline;
    }
    .name-label {
        width: 100%;
        text-align: left;
    }
    .name-input {
        width: 100px;
        margin-bottom: 2px;
    }
    .answer-input {
        width: 100%;
        margin-bottom: 10px;
    }

    @media (min-width: 768px) {
		.NameInput {
            flex-direction: row;
        }
        .name-input, .name-label {
            width: 100px;
            text-align: right;
            margin-right: 10px;
        }
        .answer-input {
            width: 80%;
        }
	}
</style>

import App from './App.svelte';

import './global.css';

const app = new App({
    target: document.body,
    props: {
        names: [
            'Adam',
            'Alex',
            'Angel',
            'Anya',
            'Aric',
            'Ben',
            'Dustin',
            'Maddy',
            'Nada',
            'Sam',
            'Sarah',
            'Sujata',
            'Tim',
            'Winston',
            'Zachary',
        ],
    }
});

export default app;

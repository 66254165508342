<script>
    import NameInput from './NameInput.svelte';
    import TextOutput from './TextOutput.svelte';
    
    export let names;
    const numBlankNames = 3;

    let textContent = "";

    let people = {};
    for (let i = 0; i < names.length + numBlankNames; i++) {
        if (names[i]) {
            people[i] = {
                id: i,
                name: names[i],
                isNameEditable: false,
                answer: "",
                sortableAnswer: "",
            }
        } else {
            people[i] = {
                id: i,
                name: "",
                isNameEditable: true,
                answer: "",
                sortableAnswer: "",
            }
        }
    }

    function handlePersonChanged(event) {
        const person = event.detail.person;

        people[person.id].name = person.name;
        people[person.id].answer = person.answer;
        people[person.id].sortableAnswer = person.sortableAnswer;

        updateTextContent();
    }

    function updateTextContent() {
        textContent = Object.values(people)
            .filter(person => person.answer.trim() != "")
            .filter(person => person.name.trim() != "")
            .sort((a, b) => {
                if (a.sortableAnswer == b.sortableAnswer) {
                    return 0;
                }
                return a.sortableAnswer < b.sortableAnswer ? -1 : 1;
            })
            .map(person => `${person.name}: ${person.answer}`)
            .join("\n");
    }
</script>

<main>
    <h1>Prompt Me!</h1>
    
    <section class="all-content">
        <section class="name-list">
            {#each Object.values(people) as person}
                <NameInput person="{person}" on:personChanged={handlePersonChanged} />
            {/each}
        </section>
        
        <section class="output">
            <TextOutput content={textContent} />
        </section>
    </section>
</main>

<style>
    main {
        text-align: center;
        padding: 1em;
        margin: 0 auto;
    }

    h1 {
        color: #14bf96;
        text-transform: uppercase;
        font-size: 4em;
        font-weight: 100;
    }

    .all-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .name-list {
        margin-bottom: 50px;
    }

    @media (min-width: 768px) {
        main {
            max-width: 1000px;
        }

        .all-content {
            flex-direction: row;
        }

        .name-list {
            margin-right: 30px;
            flex-grow: 1;
        }

        .output {
            flex-grow: 1;
        }
    }
</style>
